import React from "react";
import "./IITHMap.css";
const iithmap = require("./assets/Mapdesign (2).png");

function IITHMap() {
  return (
    <div className="iithmap">
      <div className="heading1">Map of IITH</div>
      <img src={iithmap} alt="iith map" className="map"/>
    </div>
  );
}

export default IITHMap;
