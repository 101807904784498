import React from "react";
import "./Abstract.css";
const latexTemp = require("./assets/template-CompFlu2024.zip");
const docxTemp = require("./assets/template-CompFlu2024.docx");

function Art() {
  return (
    <div className="abstract">
      <div className="heading1 abstract_mb40">The Art of Science</div>
      <div className="guidelines">
        <div className="g_bold g_title">Video contest:</div>
        <ol className="g_list">
          <li>
            The video should capture the aesthetic beauty culminating from a
            scientific phenomenon (or a combination of phenomena) while at the
            same time explaining the scientific origin. The video can contain
            experimental and/or numerical/computational results.{" "}
            <span className="g_bold">
              However, it must not contain any digital art or AI-generated
              images.{" "}
            </span>
          </li>
          <li>
            The video should be{" "}
            <span className="g_bold">
              <span className="g_underline">at most</span> 3 minutes long. Any video longer than 3 minutes will be
              summarily disqualified, irrespective of the content.
            </span>
          </li>
          <li>
            <span className="g_bold">
              The video should begin with a black title screen with white text
              stating the title of the video, along with the names of the
              creators and their affiliations.
            </span>
          </li>
          <li>
            The creators of the video should agree to the <a href="https://creativecommons.org/licenses/by-nc/4.0/" target="_blank" className="g_link">
                Creative Commons
                Attribution-NonCommercial 4.0 International License
            </a> (This entails
            that any reuse of the video must credit the author(s) and provide a
            link back to the page where selected videos will be eventually
            hosted). <span className="g_bold">
                Note that if any audio is used in the video, it is the
                responsibility of the creators to license copyrighted materials
                properly.
            </span>
          </li>
          <li>
            The video must be uploaded in <span className="g_bold">.mp4</span> format (1920x1080 resolution
            recommended). <span className="g_bold">
                Any video uploaded in a different format will be
                summarily disqualified, irrespective of the content.
            </span>
          </li>
              <li>  
          The video should not be a presentation for a talk with a voiceover. For
          inspiration you can visit:  <a href="https://gfm.aps.org/" target="_blank" className="g_link">  https://gfm.aps.org/ </a>
          </li>
          <li> 
          The video must not include materials (especially figures) identical to those
          present in the authors’ previous publications. Published materials are
          copyrighted by the journals, and hence, if used, will violate point 4.
          </li>
          <li>
          The video can be about a problem different from any abstract that the authors
          may have submitted in CompFlu 2024.
          </li>  
        </ol>
        <div className="g_bold g_title">Image contest:</div>
        <ol className="g_list">
          <li>
            Images submitted for competition may be obtained using any imaging
            technique (e.g., traditional microscopy, atomic force microscopy
            (AFM), scanning electron microscopy (SEM), high-speed imaging,
            etc.), numerical/computational technique, or obtained by combining
            multiple images (obtained from imaging and/or computational
            techniques) into one artistic image. <span className="g_bold">
                All images must have a
                connection to a scientific phenomenon or phenomena. Digital art and
                AI-generated images will be disqualified.
            </span>
          </li>
          <li>
            The image <span className="g_bold">
                should have a caption, along with a 50-word abstract
                explaining the scientific aspect of the image. These should be
                included within the image. The image should not have formulas and
                equations.
            </span>
          </li>
          <li><span className="g_bold">The image must be uploaded in .tiff/.png/.jpg format.</span></li>
          <li>
            The creators of the image should agree to the <a href="https://creativecommons.org/licenses/by-nc/4.0/" target="_blank" className="g_link">
                Creative Commons
                Attribution-NonCommercial 4.0 International License
            </a> (This entails
            that any reuse of the image must credit the author(s) and provide a
            link back to the page where selected images will be eventually
            hosted).
          </li>
          <li>
            <span className="g_bold">Images must not be identical</span> to those present in the authors’ previous
            publications. Published materials are copyrighted by the journals, and hence,    
            if used, will violate point 4.
          </li>
          <li>
            The image can be about a scientific problem different from any abstract that
            the authors may have submitted in CompFlu 2024.
          </li>
        </ol>
      </div>
      <div className="abstract_docs">
        {/*<a
          href="https://docs.google.com/forms/d/e/1FAIpQLSc-4AzOapxXaXteYje7TvCODeypmGYGWhaU1eZGS_-dfkFD_w/viewform?usp=sf_link"
          className="abstact_fw"
          target="_blank"
        >
          <div className="btn-home-enabled abstract_button">
            Click here to Submit the Art of Science
            <div className="abs_">(Deadline : <span className="strikeabs">31 July</span> 13 December)</div>
          </div>
        </a> */}

           <div className="btn-home-disabled abstract_button">
            Submission closed
          </div>
      </div>
    </div>
  );
}

export default Art;
