import React from "react";
import "./Registation.css";
import { Link } from "react-router-dom";

function Registation() {
  return (
    <div className="regis_info">
      <div className="heading1 abstract_mb40">Registration</div>

      <div className="guidelines">
        <div className="g_bold g_title">
          Guidelines for CompFlu-2024 registration
        </div>
        <ol className="g_list">
          {/*<li>
        Registration fees will be announced on or before 15th September 2024. Please keep following this page. 
        
          </li>*/}
          {/*<li>
        Registration fees mentioned here are inclusive of GST.
          </li>*/}
          <li>
            Registration for the conference covers the following expenses:
            <ul>
              <li>Registration kit</li>
              <li>Lunch on all the conference days (16th-18th December)</li>
              <li>Gala dinner on the 17th December</li>
            </ul>
          </li>

          <li>
            Registration cost{" "}
            <span className="inf_red">
              does not include accommodation on any of the days.
            </span>
          </li>
          <li>
            Accommodation arrangements have been made for all participants at
            IIT Hyderabad campus against a charge. Further details may be found
            on{" "}
            <Link to="/accomodation" className="reg_link">
              accomodation page
            </Link>
            .
          </li>
          <li>
            Please have your abstract IDs ready before filling out this form.
            This information would have been shared with you in the email about
            abstract decisions. Participants who are joint first authors are required to enter the  same
            Abstract ID when they register separately, in case both participants plan to attend Compflu-2024
          </li>
          <li>
            Fixed addon charges are only for faculty/scientists/industry
            personnel who will be accompanied by spouse/children. The addon
            charges include only 3 lunches and gala dinner.
          </li>
        </ol>
      </div>
      {<div className="info_div">INR Transactions</div>}
      {
        <div className="table_info">
          <table>
            <tr>
              <th>Category</th>
              <th>Early Bird (INR)</th>
              <th>Late (INR)</th>
              {/*<th>On Spot (INR)</th>*/}
            </tr>
            <tr>
              <td>Student (UG, PG, PhD & Post Doc)</td>
              <td>6500</td>
              <td>8000</td>
              {/*<td>10000</td>*/}
            </tr>
            <tr>
              <td>Faculty/Scientist/Industry Personnel</td>
              <td>12500</td>
              <td>15000</td>
              {/*<td>20000</td>*/}
            </tr>
            <tr>
              <td>Fixed addon charges </td>
              <td colspan="3"> 2000 </td>
            </tr>
            <tr>
              <td>End Date</td>
              <td>20-Oct-2024</td>
              <td>30-Nov-2024</td>
              {/*<td>18-Dec-2024</td>>*/}
            </tr>
          </table>
        </div>
      }
      {<div className="info_div">USD Transactions</div>}
      <div className="table_info">
        {
          <table>
            <tr>
              <th>Category</th>
              <th>Early Bird (USD)</th>
              <th>Late (USD)</th>
              {/*<th>On Spot (USD)</th>*/}
            </tr>
            <tr>
              <td>Student (UG, PG, PhD & Post Doc)</td>
              <td>100</td>
              <td>125</td>
              {/*<td>150</td>*/}
            </tr>
              <tr>
              <td>Faculty/Scientist/Industry Personnel</td>
              <td>200</td>
              <td>250</td>
              {/*<td>300</td>*/}
            </tr>
            <tr>
              <td>Fixed addon charges </td>
              <td colspan="3"> 50 </td>
            </tr>
            <tr>
              <td>End Date</td>
              <td>20-Oct-2024</td>
              <td>30-Nov-2024</td>
              {/*<td>18-Dec-2024</td>*/}
            </tr>
          </table>
        }
        {/*<div className="info_acc"><span className="inf_bold">Note:</span> Registration fees does not include accommodation. Accommodation will be provided on campus based on availability.</div>*/}
        {/* <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScVwm2xJH4CumuoqTE8wNkunu-5z9SUdwsZVYL-ZDntfvDuvA/viewform"
          className="abstact_fw"
          target="_blank"
        >
          <div className="btn-home-enabled abstract_button">
            Click here to register
          </div>
        </a> */}
        
        <div className="btn-home-disabled abstract_button">
            Registration closed
        </div>
      </div>
    </div>
  );
}

export default Registation;
