import React from "react";
import "./Organisers.css";

function Organisers() {
  return (
    <div>
      <div className="heading1 ocheading">Meeting Organisers</div>
      <div className="oc">
        <ul className="oclist">
          <a
            href=" https://www.iith.ac.in/phy/agupta/"
            target="_blank"
            className="org_nd"
          >
            <li>Anupam Gupta</li>
            <div className="org_dept">Department of Physics, IIT Hyderabad</div>
          </a>
          <a
            href="https://sites.google.com/che.iith.ac.in/softmatter/home"
            target="_blank"
            className="org_nd"
          >
            <li>Alan R Jacob</li>
            <div className="org_dept">
              Department of Chemical Engineering, IIT Hyderabad
            </div>
          </a>
          <a href="http://iyerb.weebly.com/" target="_blank" className="org_nd">
            <li>Balaji Iyer</li>
            <div className="org_dept">
              Department of Chemical Engineering, IIT Hyderabad
            </div>
          </a>
          <a
            href="https://people.iith.ac.in/hdixit/"
            target="_blank"
            className="org_nd"
          >
            <li>Harish Dixit</li>
            <div className="org_dept">
              Department of Mechanical & Aerospace Engineering, IIT Hyderabad
            </div>
          </a>
          <a
            href="https://sites.google.com/view/molecular-simulation-lab"
            target="_blank"
            className="org_nd"
          >
            <li>Himanshu Joshi</li>
            <div className="org_dept">
              Department of Biotechnology, IIT Hyderabad
            </div>
          </a>
          <a
            href="https://sites.google.com/che.iith.ac.in/softmatter/home"
            target="_blank"
            className="org_nd"
          >
            <li>Mahesh Ganesan</li>
            <div className="org_dept">
              Department of Chemical Engineering, IIT Hyderabad
            </div>
          </a>
          <a
            href="https://www.iith.ac.in/che/ramkarn/"
            target="_blank"
            className="org_nd"
          >
            <li>Ramkarn Patne</li>
            <div className="org_dept">
              Department of Chemical Engineering, IIT Hyderabad
            </div>
          </a>
          <a href="https://s2m-rdey.in/" target="_blank" className="org_nd">
            <li>Ranabir Dey</li>
            <div className="org_dept">
              Department of Mechanical & Aerospace Engineering, IIT Hyderabad
            </div>
          </a>
          <a
            href="https://sites.google.com/che.iith.ac.in/softmatter/home"
            target="_blank"
            className="org_nd"
          >
            <li>Ranajit Mondal</li>
            <div className="org_dept">
              Department of Chemical Engineering, IIT Hyderabad
            </div>
          </a>
          <a
            href="https://samavedi.weebly.com/"
            target="_blank"
            className="org_nd"
          >
            <li>Satyavrata Samavedi</li>
            <div className="org_dept">
              Department of Chemical Engineering, IIT Hyderabad
            </div>
          </a>
          <a
            href="https://sites.google.com/site/suhailrizvi/home"
            target="_blank"
            className="org_nd"
          >
            <li>Suhail Rizvi</li>
            <div className="org_dept">
              Department of Biomedical Engineering, IIT Hyderabad
            </div>
          </a>
          <a
            href="https://murelab.weebly.com/"
            target="_blank"
            className="org_nd"
          >
            <li>Suhanya Duraiswamy</li>
            <div className="org_dept">
              Department of Chemical Engineering, IIT Hyderabad
            </div>
          </a>
        </ul>
      </div>
    </div>
  );
}

export default Organisers;
