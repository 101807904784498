import React from "react";
import "./Overview.css";
const logo = require("../src/assets/comp-logo.png");

function Overview() {
  return (
    <div className="overview">
      <div className="overview-content">
        <div className="overview-logo">
          <img src={logo} alt="rheology-logo" className="imgg" />
        </div>
      </div>
      <div className="overview-txt">
        <div className="heading1 headd1">About CompFlu-2024</div>
        {/* <div className="heading4 width80">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa velit
          impedit quo a incidunt inventore fuga id ad adipisci facilis?
        </div> */}
        <div className="para width80">
          CompFlu-2024 will be hosted by IIT Hyderabad and Indian Society of
          Rheology from 16-18 Dec 2024. Since 2002, the Complex Fluids meeting
          has served as a key scientific gathering for India's dynamic, vibrant
          and ever growing soft matter research community, bringing together
          academia and industry across various science and engineering
          disciplines. With over 500 participants, including regular
          international attendees, it has become a pivotal platform for
          collaboration and knowledge exchange.
          <br />
          We are pleased to announce that CompFlu-2024 will take place at IIT
          Hyderabad, and extend a warm invitation to researchers from all
          fields, specializing in experimental, theoretical, or simulation
          aspects of soft matter, to participate in this event. The theme for CompFlu-2024
          is <b>'Soft Matter & Beyond'</b>.
        </div>
      </div>
    </div>
  );
}

export default Overview;
