import React from "react";
import {
  FaEnvelope,
  FaMapMarkerAlt,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";
import "./Contact.css";

function Contact() {
  return (
    <div className="contact">
      <div className="heading1">Contact Us</div>
      <div className="gridc">
        <div>
          <div className="email">
            <FaEnvelope />
            <a href="mailto:compflu2024@iith.ac.in">compflu2024@iith.ac.in</a>
          </div>
          <div className="email">
            <FaMapMarkerAlt />
            <a
              className="temp1"
              href="https://maps.app.goo.gl/Y1gKC7k7FwuNXHof6"
            >
              IIT Hyderabad, Kandi, Sangareddy, <br className="temp22" />{" "}
              Hyderabad - 502284
            </a>
          </div>
        </div>
        <div>
          <div className="email tempp">
            <FaInstagram />
            <a href="https://www.instagram.com/compflu2024/" target="_blank">
              compflu2024
            </a>
          </div>
          <div className="email twti">
            <i className="fa-brands fa-x-twitter"></i>
            <a href="https://twitter.com/compflu2024" target="_blank">
              compflu2024
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
