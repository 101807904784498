import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div>
      <div className="about-container ">
        <div className="black-bg">
          <div className="title">
            <div className="title-main">CompFlu-2024</div>
            <p>IIT Hyderabad</p>
            <div className="title-dates">16 - 18 December 2024</div>
            <div className="buttons-home">
           { <Link to={"/venue#taxi"} className="btn-home-enabled">
                <button className="btn-home-enabled-1">
                  Taxi <br />
                  Services
                </button> 
              </Link> }
              <Link to={"/program"} className="btn-home-enabled">
                <button className="btn-home-enabled-1">
                  Detailed  <br />
                  Schedule
                </button>
              </Link>
              <Link to={"/art"} className="btn-home-enabled">
                <button className="btn-home-enabled-1">
                  Art of <br />
                  Science
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
