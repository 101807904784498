import React from "react";
import "./Technical.css";
function Technical() {
  return (
    <div className="tech_align tech">
      <div className="heading1">Technical Program and Chairs</div>
      <div className="tech_content">
        <div className="tech_align heading4">Polymers & composites (PC)</div>
        <div className="tech_flex">
          <div className="tech_chairs">
            Swaminath Bharadwaj
            <br />
            <span className="tech_univ">Shiv Nadar University</span>
            <br />
          </div>
          <div className="tech_chairs">
            Prachi Thareja
            <br />
            <span className="tech_univ">IIT Gandhinagar</span>
            <br />
          </div>
        </div>
      </div>
      <div className="tech_content">
        <div className="tech_align heading4">Biological Soft Matter (BSM)</div>
        <div className="tech_flex">
          <div className="tech_chairs">
            Tamal Das
            <br />
            <span className="tech_univ">TIFR Hyderabad</span>
            <br />
          </div>
          <div className="tech_chairs">
            Mithun Radhakrishna
            <br />
            <span className="tech_univ">IIT Gandhinagar</span>
            <br />
          </div>
        </div>
      </div>
      <div className="tech_content">
        <div className="tech_align heading4">
          Colloids, emulsions & foams (CEF)
        </div>
        <div className="tech_flex">
          <div className="tech_chairs">
            Venkateshwar Dugyala
            <br />
            <span className="tech_univ">IISER Bhopal</span>
            <br />
          </div>
          <div className="tech_chairs">
            Vijay Chikkadi
            <br />
            <span className="tech_univ">IISER Pune</span>
            <br />
          </div>
        </div>
      </div>
      <div className="tech_content">
        <div className="tech_align heading4">
          Active & responsive soft matter (ARSM)
        </div>
        <div className="tech_flex">
          <div className="tech_chairs">
            Shraddha Mishra
            <br />
            <span className="tech_univ">IIT (BHU) Varanasi</span>
            <br />
          </div>
          <div className="tech_chairs">
            Richa Karmakar
            <br />
            <span className="tech_univ">IIT Madras</span>
            <br />
          </div>
        </div>
      </div>
      <div className="tech_content">
        <div className="tech_align heading4">Interfacial phenomena (IF)</div>
        <div className="tech_flex">
          <div className="tech_chairs">
            Prosenjit Sen
            <br />
            <span className="tech_univ">IISc Banglore</span>
            <br />
          </div>
          <div className="tech_chairs">
            Uddipta Ghosh
            <br />
            <span className="tech_univ">IIT Gandhinagar</span>
            <br />
          </div>
        </div>
      </div>
      <div className="tech_content">
        <div className="tech_align heading4">
          Rheology & constitutive modeling (RCM)
        </div>
        <div className="tech_flex">
          <div className="tech_chairs">
            Ravi Kumar Pujala
            <br />
            <span className="tech_univ">IISER Tirupati</span>
            <br />
          </div>
          <div className="tech_chairs">
            Kushboo Suman
            <br />
            <span className="tech_univ">IIT Mardas</span>
            <br />
          </div>
        </div>
      </div>
      <div className="tech_content">
        <div className="tech_align heading4">Membranes & thin films (MTF)</div>
        <div className="tech_flex">
          <div className="tech_chairs">
            Sovan Lal Das
            <br />
            <span className="tech_univ">IIT Palakkad</span>
            <br />
          </div>
          <div className="tech_chairs">
            Sajal Ghosh
            <br />
            <span className="tech_univ">Shiv Nadar University</span>
            <br />
          </div>
        </div>
      </div>

      <div className="tech_content">
        <div className="tech_align heading4">Granular flow (GF)</div>
        <div className="tech_flex">
          <div className="tech_chairs">
            Anki Reddy
            <br />
            <span className="tech_univ">IIT Tirupati</span>
            <br />
          </div>
          <div className="tech_chairs">
            Anurag Tripathi
            <br />
            <span className="tech_univ">IIT Kanpur</span>
            <br />
          </div>
        </div>
      </div>
      <div className="tech_content">
        <div className="tech_align heading4">
          Glasses & amorphous materials (GAM)
        </div>
        <div className="tech_flex">
          <div className="tech_chairs">
            Pinaki Chaudhuri
            <br />
            <span className="tech_univ">IMSc Chennai</span>
            <br />
          </div>
          <div className="tech_chairs">
            Smarajit Karmakar
            <br />
            <span className="tech_univ">TIFR Hyderabad</span>
            <br />
          </div>
        </div>
      </div>

      <div className="tech_content">
        <div className="tech_align heading4">
          Sustainability in soft materials (SSM)
        </div>
        <div className="tech_flex">
          <div className="tech_chairs">
            Guru Kumaraswamy
            <br />
            <span className="tech_univ">IIT Bombay</span>
            <br />
          </div>
          <div className="tech_chairs">
            Kadhiravan Shanmuganadhan
            <br />
            <span className="tech_univ">NCL Pune</span>
            <br />
          </div>
        </div>
      </div>
      <div className="tech_content">
        <div className="tech_align heading4">Multiphase flows (MF)</div>
        <div className="tech_flex">
          <div className="tech_chairs">
            Prasad Perlekar
            <br />
            <span className="tech_univ">TIFR Hyderabad</span>
            <br />
          </div>
          <div className="tech_chairs">
            Partha Goswami
            <br />
            <span className="tech_univ">IIT Bombay</span>
            <br />
          </div>
        </div>
      </div>

      <div className="tech_content">
        <div className="tech_align heading4">Gels & networks (GN)</div>
        <div className="tech_flex">
          <div className="tech_chairs">
            Nisha P<br />
            <span className="tech_univ">NIIST Thiruvanthapuram</span>
            <br />
          </div>
          <div className="tech_chairs">
            Saikat Roy
            <br />
            <span className="tech_univ">IIT Ropar</span>
            <br />
          </div>
        </div>
      </div>
      <div className="tech_content">
        <div className="tech_align heading4">
          Functional soft materials (FSM)
        </div>
        <div className="tech_flex">
          <div className="tech_chairs">
            Kamendra Sharma
            <br />
            <span className="tech_univ">IIT Bombay</span>
            <br />
          </div>
          <div className="tech_chairs">
            NV Subramanyan
            <br />
            <span className="tech_univ">NIT Calicut</span>
            <br />
          </div>
        </div>
      </div>
      <div className="tech_content">
        <div className="tech_align heading4">
          Microfluidics & microscale transport (MMT)
        </div>
        <div className="tech_flex">
          <div className="tech_chairs">
            Jaya Prakash
            <br />
            <span className="tech_univ">BITS Hyderabad</span>
            <br />
          </div>
          <div className="tech_chairs">
            Suhanya Duraiswamy
            <br />
            <span className="tech_univ">IIT Hyderabad</span>
            <br />
          </div>
        </div>
      </div>
      <div className="tech_content">
        <div className="tech_align heading4">Industrial applications (IA)</div>
        <div className="tech_flex">
          <div className="tech_chairs">
            Saswati Pujari
            <br />
            <span className="tech_univ">Hindustan Unilever Limited</span>
            <br />
          </div>
          <div className="tech_chairs">
            Pankaj Doshi
            <br />
            <span className="tech_univ">Pfizer India</span>
            <br />
          </div>
        </div>
      </div>
      <div className="tech_content">
        <div className="tech_align heading4">
          Early career researchers (ECR)
        </div>
        <div className="tech_flex">
          <div className="tech_chairs">
            Mahesh Tirumkudulu
            <br />
            <span className="tech_univ">IIT Bombay</span>
            <br />
          </div>
          <div className="tech_chairs">
            Abhijit Deshpande
            <br />
            <span className="tech_univ">IIT Madras</span>
            <br />
          </div>
        </div>
      </div>
      <div className="tech_content">
        <div className="tech_align heading4 tech_align">
          Academia-industry collaboration (AIC)
        </div>
        <div className="tech_flex">
          <div className="tech_chairs">
            Sreejit Nair
            <br />
            <span className="tech_univ">Momentive Performance Materials</span>
            <br />
          </div>
          <div className="tech_chairs">
            Basavaraj Madivala
            <br />
            <span className="tech_univ">IIT Madras</span>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Technical;
