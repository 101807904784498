import React from 'react'

function BAbstracts() {
  return (
    <div className="detailed">
        <div className='heading1'>Book of Abstracts</div>
        <div className='detail'>Coming Soon...</div>
    </div>
  )
}

export default BAbstracts