import React from "react";
import "./Program.css";

function Program() {
  return (
    <div className="program">
      {/*<div className="heading1">Program</div>
      <div className="para_in">
        The CompFlu-2024 schedule can be found{" "}
        here:{" "}
      </div>*/}
      <div className="program_doc_container">
        <iframe className="program_doc" src="https://docs.google.com/document/d/e/2PACX-1vQj_O9n3URTIn1BUFn0Bs_Rm5Z1npvqZgtd36DZa5KzEbaXIDyqACrkU9dGtUemdg/pub?embedded=true"></iframe>
      </div>
    </div>
  );
}

export default Program;
