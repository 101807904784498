import { Carousel } from "react-carousel-minimal";
import "./Events.css";
const BTS1 = require("./assets/BTS1.jpg");

function Events() {
  const data = [
    { 
      image: BTS1,
      caption: "BTS: Getting ready for CompFlu-2024",
    }
  ];

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };
  return (
    <div className="events" style={{zIndex:-40}}>
      <div>
        <div className="heading1">Events</div>
        <div
          style={{
            padding: "0 20px",
          }}
        >
          <Carousel
            data={data}
            time={2000}
            width="850px"
            height="500px"
            captionStyle={captionStyle}
            radius="10px"
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={true}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "850px",
              maxHeight: "500px",
              margin: "40px auto",
              zIndex: "-5000"
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Events;
