import React, { useState } from "react";
import "./App.css";
import Home from "./Home";
import Overview from "./Overview";
import Contact from "./Contact";
import Dates from "./Dates";
import useHashScroll from "./HashScroll";
import Video from "./Video";
import Poster from "./Poster";
import Events from "./Events"

function App() {
  // const [isDialogOpen, setDialogOpen] = useState(true);
  // useEffect(() => {
  //   const hash = window.location.hash;
  //   if (hash) {
  //     const id = hash.replace('#', '');
  //     const element = document.getElementById(id);
  //     if (element) {
  //       element.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   }
  // }, []);
  useHashScroll();
  return (
    <div className="App">
      {/* {isDialogOpen && (
        <div className="dialog">
          <button className="close-btn" onClick={() => setDialogOpen(false)}>
            ✕
          </button>
          <p className="dialog_head">Abstract Submission <br/> is now Open!!</p>
          <button className="register-btn">
            <Link to="/abstract"  >Click Here for Abstract Submission</Link>
          </button>
        </div>
      )} */}
      <div>
        <Home />
        <section id="about">
          <Overview />
        </section>
  {/*<Poster />*/}
   <Events />
        <section id="dates">
          <Dates />
        </section>
        <Video />
        <section id="contact">
          <Contact />
        </section>
      </div>
    </div>
  );
}

export default App;
