import React from "react";

function Creche() {
  return (
    <div>
      <div style={{ textAlign: "left", margin: "auto 12%" }}>
        <div
          className="heading1"
          style={{
            textAlign: "left",
            fontSize: "2.5rem",
            color: "#000099",
            marginBottom: "15px",
          }}
        >
          Crèche Facility at IITH
        </div>
        <p style={{ fontSize: "1.4rem" }}>
          In case you are a parent and planning to attend Compflu 2024 with
          toddlers & children, a Crèche facility is avaialble inside the IITH
          campus at the faculty towers, a 5 min walking distance from the
          international guest house. If you have any more queries about using
          this facility do write to us.
        </p>
      </div>
    </div>
  );
}

export default Creche;
