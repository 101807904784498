import React from "react";
import "./Poster.css";
const img = require("./assets/poster.png");

function Poster() {
  return (
    <div>
      <img src={img} alt="compflu_poster" className="poster" />
    </div>
  );
}

export default Poster;
