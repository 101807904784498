import React from "react";
import "./Video.css";
function Video() {
  return (
    <div className="videofull">
      <div className="heading1">Campus Tour</div>
      <iframe
        width="760"
        height="430"
        className="video"
        src="https://www.youtube.com/embed/siVHkgumOy0?si=XdLZ60yesQq2g4g2"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
        allowfullscreen
      ></iframe>
    </div>
  );
}

export default Video;
