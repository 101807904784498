import React from 'react'

function W_DetProg() {
  return (
    <div className="program">
      {/*<div className="heading1">Program</div>
      <div className="para_in">
        The CompFlu-2024 schedule can be found{" "}
        here:{" "}
      </div>*/}
      <div className="program_doc_container">
        <iframe className="program_doc" src="https://docs.google.com/document/d/e/2PACX-1vTTGnZOopw86-L4FrpyxVvQ-9eGaPaF0eYlGKPs1Dhs3jlPWEEF8A1S_rgRvOaa8Q/pub?embedded=true"></iframe>
      </div>
    </div>
  )
}

export default W_DetProg