import React from 'react'
import './Detailed.css'

function Detailed() {
    return (
    {/*<div className="program">
      <div className="heading1">Program</div>
      <div className="para_in">
        The CompFlu-2024 schedule can be found{" "}
        here:{" "}
        </div>
      <div className="program_doc_container">
        <iframe className="program_doc_dett" src="https://docs.google.com/document/d/e/2PACX-1vQvW_iyrlHt48pNfLvg3cS3MSJTdMbH8etqjBqpOm7cBiGw1mb3lWluXB0qRVlr9rSfuJ_FHrPf0zpp/pub"></iframe>
      </div>
    </div>*/}
  )
}

export default Detailed
